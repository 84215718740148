import { render, staticRenderFns } from "./MemberShopPointLinkDetail.vue?vue&type=template&id=4073e127&scoped=true"
import script from "./MemberShopPointLinkDetail.vue?vue&type=script&lang=js"
export * from "./MemberShopPointLinkDetail.vue?vue&type=script&lang=js"
import style0 from "./MemberShopPointLinkDetail.vue?vue&type=style&index=0&id=4073e127&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4073e127",
  null
  
)

export default component.exports