<template>
  <main v-loading="loading.order" class="member-shop-order-detail">
    <PageTitle
      title="點數連結管理列表"
      icon="chevron_left"
      btn="匯出"
      @iconClick="$router.push({ name: 'MemberShopPointLink' })"
      @btnClick="showExportOptions = true"
    />
    <div v-loading="loading.order" class="content-wrapper">
      <MemberShopPointLinkInfoSection :pointLink="linkData" />
      <MemberShopPointTimesInfoSection :pointLink="linkData" @refresh="refresh" />
      <MemberShopPointLinksTable
        :pointLink="linkData"
        :tableData="tableData"
        @refresh="refresh"
      />
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh"
      />
    </div>
    <ExportOptionsDialog
      v-if="showExportOptions"
      allRange
      info="提醒：資料匯出上限為50,000筆，若超過則會匯出最新的50,000筆。"
      @close="showExportOptions = false"
      @export="prepareExport"
    />
    <ExportDialog
      v-if="exportState.modal"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :content="exportState.content"
      :percentage="totalPercentege"
      @close="resetExport"
    />
  </main>
</template>

<script>
import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import MemberShopPointLinkInfoSection from './components/MemberShopPointLinkInfoSection.vue'
import MemberShopPointTimesInfoSection from './components/MemberShopPointTimesInfoSection.vue'
import MemberShopPointLinksTable from './components/MemberShopPointLinksTable.vue'
import { FindShopPointLink, GetShopPointLinkCode, GetShopPointLinkCodeCount } from '@/api/shopPoint'
import { useRoute } from 'vue-router/composables'
import { get } from 'lodash'
import { useTable } from '@/use/table'
import { useFetch } from '@/use/fetch'
import { formatDate } from '@/utils/date'
import { usedPointStatusConfig, pointLinkStatusConfig } from '@/config/point'
import { useShop } from '@/use/shop'
import { useExport } from '@/use/export'

export default defineComponent({
  name: 'MemberShopPointLinkDetail',
  components: {
    PageTitle,
    MemberShopPointLinkInfoSection,
    MemberShopPointTimesInfoSection,
    MemberShopPointLinksTable,
    ExportOptionsDialog,
    ExportDialog,
  },
  setup (props) {
    const route = useRoute()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      pageStartIndex,
      fetchData,
      fetchDataCount,
    } = useTable()
    const { exportState, resetExport, getExportData, totalDataCount, totalPercentege, ExportMoreSheetExcel } = useExport()
    const { shopId } = useShop()
    const shopPointLinkId = computed(() => route.params.id)
    const loading = reactive({
      order: false,
    })
    const linkData = ref(null)
    const showExportOptions = ref(false)

    const findShopPointLink = async (payload) => {
      const [res, err] = await FindShopPointLink(payload)
      if (err) return window.$message.error(err)
      linkData.value = res
    }
    const refresh = async () => {
      const payload = {
        shopId: shopId.value,
        id: shopPointLinkId.value,
      }
      loading.order = true
      await Promise.allSettled([
        findShopPointLink(payload),
        getTableData(),
      ])
      loading.order = false
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        ShopPointLinkId: shopPointLinkId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await Promise.allSettled([
        fetchData(GetShopPointLinkCode, payload),
        fetchDataCount(GetShopPointLinkCodeCount, payload),
      ])
    }
    const prepareExport = async () => {
      showExportOptions.value = false
      exportState.modal = true
      exportState.exportting = true
      const payload = {
        shopId: shopId.value,
        ShopPointLinkId: shopPointLinkId.value,
        start: pageStartIndex.value,
        // limit: tableOptions.pageLimit,
      }

      try {
        const [res, err] = await GetShopPointLinkCodeCount(payload)
        if (err) window.$message.error(err)
        exportState.dataCount = [res.count]
        if (!totalDataCount.value) {
          exportState.content = '尚無資料可匯出'
          exportState.error = true
          window.$message.warning('尚無資料可匯出')
          return resetExport()
        }

        const data = await getExportData({ stage: 0, fetchAPI: GetShopPointLinkCode, payload })
        const formatData = await formatExportData(data)
        exportState.success = true
        exportState.content = '匯出完成'
        ExportMoreSheetExcel(formatData, '點數連結紀錄')
      } catch (error) {
        console.log(error)
        window.$message.error(error)
        exportState.exportting = false
        exportState.error = true
      }
    }
    const formatExportData = async (exportData) => {
      const data = []
      for (const item of exportData) {
        const row = await formatRecordData(item)
        data.push(row)
      }
      const pointLinkData = await formatPointLinkData()
      const sheetList = [
        {
          sheetName: '點數連結基本資訊',
          data: pointLinkData,
        },
        {
          sheetName: '點數連結紀錄',
          data,
        },
      ]
      return sheetList
    }
    const formatPointLinkData = async () => {
      const formatedData = [{
        連結名稱: get(linkData.value, 'name'),
        開始時間: get(linkData.value, 'startAt') ? formatDate(get(linkData.value, 'startAt')) : '-',
        結束時間: get(linkData.value, 'endAt') ? formatDate(get(linkData.value, 'endAt')) : '-',
        發放點數: `${get(linkData.value, 'point')} 點`,
        連結狀態: get(linkData.value, 'status') ? linkStatus(get(linkData.value, 'status'), 'label') : '-',
        已領取數量: get(linkData.value, 'usedLinks'),
        連結數量: get(linkData.value, 'totalLinks'),
      }]

      return formatedData
    }
    const formatRecordData = async (i) => {
      const formatedData = {
        點數連結: get(i, 'link'),
        點數序號: get(i, 'code'),
        建立時間: get(i, 'createdAt') ? formatDate(get(i, 'createdAt')) : '-',
        領取狀態: get(i, 'status') ? linkUsedStatus(get(i, 'status'), 'label') : '-',
        領取人: get(i, 'Member.UserInfo.name') || '-',
        領取時間: get(i, 'usedAt') ? formatDate(get(i, 'usedAt'), 'YYYY-MM-DD HH:mm') : '-',
      }

      return formatedData
    }
    const linkStatus = (status, attr) => {
      return get(pointLinkStatusConfig[status], attr)
    }
    const linkUsedStatus = (status, attr) => {
      return get(usedPointStatusConfig[status], attr)
    }
    onMounted(async () => {
      await refresh()
    })
    return {
      linkData,
      loading,
      refresh,
      tableData,
      tableDataCount,
      tableOptions,
      showExportOptions,
      prepareExport,
      resetExport,
      exportState,
      totalPercentege,
    }
  },
})
</script>

<style scoped lang="postcss">
.content-wrapper {
  @apply flex flex-col gap-[16px];
}
</style>
