<template>
  <BaseDialog
    title="新增連結數量"
    width="600px"
    @close="$emit('close')"
    @confirm="onConfirm"
    :btn1Loading="loading"
  >
    <div>
      <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <el-form-item label="輸入欲新增的連結數" prop="amount">
          <el-input-number v-model.trim="formData.amount" placeholder="請輸入" />
        </el-form-item>
      </el-form>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { defineComponent, reactive, ref, computed } from 'vue'
import { noEmptyRules, integerRules, minRules, maxNumberRules } from '@/validation'
import { CreateShopPointLinkCode } from '@/api/shopPoint'
import formUtils from '@/utils/form'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'MemberShopAddPointLinkModal',
  components: { BaseDialog },
  props: {
    pointLinkId: { type: String, default: '' },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const loading = ref(false)
    const formRef = ref(null)
    const formData = reactive({
      amount: null,
    })
    const formRules = {
      amount: [noEmptyRules(), integerRules(), minRules(1), maxNumberRules(10000, '最多不可超過 10000 個連結')],
    }

    const onConfirm = async () => {
      if(loading.value) return
      loading.value = true
      const pass = await formUtils.checkForm(formRef.value)
      if (!pass) {
        loading.value = false
        return
      }
      const [res, err] = await CreateShopPointLinkCode({
        shopId: shopId.value,
        shopPointLinkId: props.pointLinkId,
        amount: formData.amount,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      emit('refresh')
      emit('close')
    }

    return { onConfirm, formData, formRules, formRef, loading }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-input {
  @apply w-full;
}
</style>
