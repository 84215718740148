<template>
  <div>
    <GridInfoContainer title="給點次數" labelWidth="120" labelGap="12">
      <template #default="slotData">
        <InfoContainerItem :data="slotData" label="已領取數量" :value="displayData.usedLinks" />
        <div class="info-container-item gap-[12px]">
          <p class="info-item-label w-[120px]">連結數量</p>
          <p class="info-item-value">{{ displayData.totalLinks }}
            <el-button
              type="text"
              class="btn edit"
              @click="modal.addLinks = true"
              >
              新增連結數量
            </el-button>
          </p>
        </div>
      </template>
    </GridInfoContainer>
    <MemberShopAddPointLinkModal :pointLinkId="displayData.id" v-if="modal.addLinks" @close="modal.addLinks = false" @refresh="refresh" />
  </div>
</template>

<script>
import GridInfoContainer from '@/components/Container/GridInfoContainer.vue'
import InfoContainerItem from '@/components/Container/InfoContainerItem.vue'
import MemberShopAddPointLinkModal from './MemberShopAddPointLinkModal.vue'
import { defineComponent, computed, reactive } from 'vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'MemberShopPointTimesInfoSection',
  components: { GridInfoContainer, InfoContainerItem, MemberShopAddPointLinkModal },
  props: {
    pointLink: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const modal = reactive({
      addLinks: false,
    })
    const displayData = computed(() => {
      const pointLink = get(props, 'pointLink')
      return {
        id: get(pointLink, 'id'),
        totalLinks: get(pointLink, 'totalLinks'),
        usedLinks: get(pointLink, 'usedLinks'),
      }
    })
    const refresh = () => {
      emit('refresh')
    }
    return { displayData, modal, refresh }
  },
})
</script>

<style scoped lang="postcss">
.info-container-item {
  @apply flex items-center;
}

.info-item-label {
  @apply text-normal text-gray-80;
}

.info-item-value {
  @apply text-normal text-gray-80;
  @apply font-medium;
}
</style>
